import { useState, useEffect } from 'react'
import queryString from 'query-string'
import { getValues } from 'myUtils/firebase'
import { useQuery } from '@apollo/react-hooks'
import { GET_EVENT_SESSIONS } from 'myUtils/queries'
import { sessionStages } from 'myUtils/constants'

const { LOADING, ERROR, SUCCESS } = sessionStages

function getStyle (style) {
  try {
    const json = JSON.parse(style)
    if (typeof json === 'object') {
      return json
    }
  } catch (err) {
    return null
  }
}

const useSessions = () => {
  const [logo, setLogo] = useState(null)
  const [teamName, setTeamName] = useState(null)
  const [stage, setStage] = useState(LOADING)
  const [backgroundStyle, setBackgroundStyle] = useState({})
  const [errorMsg, setErrorMsg] = useState(null)

  const { query } = queryString.parseUrl(window.location.href)
  const { pk } = query
  const { loading, data: eventSessions } = useQuery(GET_EVENT_SESSIONS, {
    variables: { guestAppPk: pk },
  })

  const invalidToken = () => {
    setErrorMsg('Invalid Token')
    setStage(ERROR)
  }

  useEffect(() => {
    if (!loading) {
      if (eventSessions === undefined || !eventSessions.guestSessionsForCustomer) {
        invalidToken()
      }
    }
  }, [loading, eventSessions])

  useEffect(() => {
    if (!loading && stage === LOADING) {
      if (eventSessions !== undefined && teamName) setStage(SUCCESS)
    }
  }, [loading, stage, eventSessions, teamName])

  const fetchCompany = async () => {
    try {
      let company
      if (query.id) company = query.id
      else [company] = window.location.hostname.split('.')

      const { logo, teamName, backgroundStyle } = await getValues(`/companies/${company}`)

      if (backgroundStyle) setBackgroundStyle(getStyle(backgroundStyle))
      if (logo) setLogo(logo)
      if (teamName) setTeamName(teamName)
    } catch (err) {
      console.error(err)
    }
  }

  const init = () => {
    fetchCompany()
  }

  return {
    init,
    backgroundStyle,
    logo,
    teamName,
    stage,
    errorMsg,
    eventSessions
  }
}

export default useSessions
