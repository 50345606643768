import { creditCardStages } from 'myUtils/constants'
import queryString from 'query-string'
import { getValues } from 'myUtils/firebase'
import { useState, useEffect } from 'react'
import { PAYMENT_METHOD_QUERY } from 'myUtils/queries'
import { PROCESS_PAYMENT_METHOD } from 'myUtils/mutations'
import { useQuery, useMutation } from '@apollo/react-hooks'

const {
  LOADING,
  FORM,
  ERROR,
  CONFIRMATION
} = creditCardStages

function getStyle (style) {
  try {
    const json = JSON.parse(style)
    if (typeof json === 'object') {
      return json
    }
  } catch (err) {
    return null
  }
}

const useCreditCard = () => {
  const [logo, setLogo] = useState(null)
  const [teamName, setTeamName] = useState(null)
  const [stage, setStage] = useState(LOADING)
  const [backgroundStyle, setBackgroundStyle] = useState({})
  const [errorMsg, setErrorMsg] = useState(null)
  const [buttonStyle, setButtonStyle] = useState(null)
  const [buttonStyleDeactivated, setButtonStyleDeactivated] = useState(null)
  const [addCardConfirmationMessage, setAddCardConfirmationMessage] = useState('Your card has been securely stored.')
  const [organizationId, setOrganizationId] = useState(process.env.REACT_APP_ENV === 'production' ? '1999' : '1')
  const { query } = queryString.parseUrl(window.location.href)
  const { token } = query
  const { loading, data: stripeData } = useQuery(PAYMENT_METHOD_QUERY, {
    variables: { encryptedToken: token }
  })
  const [savePaymentMutation] = useMutation(PROCESS_PAYMENT_METHOD)

  const savePayment = async () => {
    try {
      const gqlRes = await savePaymentMutation({
        variables: { intentId: stripeData.paymentMethod.intentId }
      })
      if (gqlRes && gqlRes.data && gqlRes.data.processPaymentMethod) setStage(CONFIRMATION)
      else {
        invalidToken()
      }
    } catch (err) {
      invalidToken()
    }
  }

  const invalidToken = () => {
    setErrorMsg('Invalid Token')
    setStage(ERROR)
  }

  useEffect(() => {
    if (!loading) {
      if (stripeData === undefined || !stripeData.paymentMethod) {
        invalidToken()
      }
    }
  }, [loading, stripeData])

  useEffect(() => {
    if (!loading && stage === LOADING) {
      if (stripeData !== undefined && teamName) {
        if (stripeData.paymentMethod && String(stripeData.paymentMethod.orgId) !== String(organizationId)) invalidToken()
        else setStage(FORM)
      }
    }
  }, [loading, organizationId, stage, stripeData, teamName])

  const fetchCompany = async () => {
    try {
      let company
      if (query.id) company = query.id
      else [company] = window.location.hostname.split('.')

      const {
        logo,
        teamName,
        orgId,
        buttonStyle,
        backgroundStyle,
        addCardConfirmationMessage,
        buttonStyleDeactivated
      } = await getValues(`/companies/${company}`)

      if (backgroundStyle) setBackgroundStyle(getStyle(backgroundStyle))
      if (logo) setLogo(logo)
      if (orgId) setOrganizationId(orgId)
      if (addCardConfirmationMessage) setAddCardConfirmationMessage(addCardConfirmationMessage)
      if (teamName) setTeamName(teamName)
      if (buttonStyle) setButtonStyle(getStyle(buttonStyle))
      if (buttonStyleDeactivated) setButtonStyleDeactivated(getStyle(buttonStyleDeactivated))
    } catch (err) {
      console.error(err)
    }
  }

  const init = () => {
    fetchCompany()
  }

  return {
    init,
    backgroundStyle,
    stripeData,
    savePayment,
    logo,
    stage,
    buttonStyle,
    invalidToken,
    addCardConfirmationMessage,
    buttonStyleDeactivated,
    errorMsg,
    teamName
  }
}

export default useCreditCard
