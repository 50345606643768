import React, { useEffect } from 'react'
import Div100vh from 'react-div-100vh'
import {sessionStages} from 'myUtils/constants'
import Logo from "../myComponents/Logo";
import useSessions from 'myHooks/useSessions'
import PoweredByConcierge from "../myComponents/PoweredByConcierge";
import {SessionsProvider} from "../myContexts/SessionsContext";
import EventSessions from './schedule/EventSessions';

const {LOADING, ERROR, SUCCESS} = sessionStages

const Schedule = () => {
  const sessionsData = useSessions();
  const { init, stage, logo, backgroundStyle, teamName, errorMsg, eventSessions } = sessionsData

  useEffect(() => {
    init();
  }, [init])

  const getBackgroundStyle = () => {
    const {innerWidth} = window
    if (innerWidth > 900) return backgroundStyle
    return {}
  }

  return (
    <Div100vh>
      <div style={getBackgroundStyle()} className='web-SmsOptIn'>
        <SessionsProvider value={sessionsData}>
          <div className='web-SmsForm-container'>
            {logo && <Logo logo={logo}/>}
            <div className='web-SmsForm-title'>{teamName}</div>
            <br/>
            {stage === LOADING && <div>Loading...</div>}
            {stage === ERROR && <div className='web-SmsForm-error'><p>{errorMsg}</p></div>}
            {stage === SUCCESS && <EventSessions sessions={eventSessions.guestSessionsForCustomer} /> }
            <PoweredByConcierge/>
          </div>
        </SessionsProvider>
      </div>
    </Div100vh>
  )
}

export default Schedule
