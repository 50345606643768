import gql from 'graphql-tag'

export const PAYMENT_METHOD_QUERY = gql`
  query paymentMethod($encryptedToken: String!) {
    paymentMethod(encryptedToken: $encryptedToken) {
      id
      intentId
      orgId
      clientSecret
      orgStripePublishableKey
    }
  }
`

export const GET_EVENT_SESSIONS = gql`
  query GuestSessionsForCustomerQuery($guestAppPk: String!) {
    guestSessionsForCustomer(guestAppPk: $guestAppPk) {
      id
      name
      description
      startDatetimeUtc
      endDatetimeUtc
      locationName
      locationUrl
    }
  }
`