import React from 'react';
import { formatStartEndDate } from 'myUtils/formatter';

const EventSessions = ({ sessions }) => {
  return (
    <>
      {sessions.length > 0 ? sessions.map((session) => (
        <div className='text-left mb-4' key={session.id}>
          <h3 className='font-16 font-semibold text-gray-900 mb-1'>{session.name}</h3>
          <p className='mb-1'>{session.description}</p>
          <div className='flex justify-between text-gray-500'>
            <p className='font-14 mb-0'>{formatStartEndDate(session.startDatetimeUtc, session.endDatetimeUtc)}</p>
            <a href={session.locationUrl} target='_blank' className='font-14' rel="noopener noreferrer">
              {session.locationName}
            </a>
          </div>
        </div>
      )) : <p>No events found.</p>}
    </>
  );
}

export default EventSessions;