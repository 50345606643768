import { useState } from 'react'
import { getValues } from 'myUtils/firebase'

function getStyle (style) {
  try {
    const json = JSON.parse(style)
    if (typeof json === 'object') {
      return json
    }
  } catch (err) {
    return null
  }
}

const useOrg = () => {
  const [headline, setHeadline] = useState(null)
  const [logo, setLogo] = useState(null)
  const [teamName, setTeamName] = useState(null)
  const [backgroundStyle, setBackgroundStyle] = useState({})
  const init = () => {
    const initCompany = async () => {
      try {
        let company = window.location.hostname.split('.')[0]
        const {
          logo,
          teamName,
          backgroundStyle,
          headline
        } = await getValues(`/companies/${company}`)
        if (headline) setHeadline(headline)
        if (logo) setLogo(logo)
        if (teamName) setTeamName(teamName)
        if (backgroundStyle) setBackgroundStyle(getStyle(backgroundStyle))
      } catch (err) {
        console.error(err)
      }
    }
    return initCompany();
  }

  return {
    init,
    logo,
    headline,
    backgroundStyle,
    teamName
  }
}

export default useOrg
